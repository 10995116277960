<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Delivery Receipts
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            class="mobile_create_button"
            :to="{ name: 'purchasing-delivery-receipts-create' }"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Delivery Receipt</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <b-tab
            :active="$route.params.type == 'pending'"
            lazy
            @click="changeRoute('pending')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending <span class="rounded-number">{{ pendingReceiptCount }}</span></span>
            </template>
            <pending-delivery-receipts
              class="mt-2 pt-75"
              @emitCountDeliveryReceipts="countDeliveryReceipts"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'linked'"
            lazy
            @click="changeRoute('linked')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Linked <span class="rounded-number">{{ linkedReceiptCount }}</span></span>
            </template>
            <linked-delivery-receipts
              class="mt-2 pt-75"
              @emitCountDeliveryReceipts="countDeliveryReceipts"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'all-receipts'"
            lazy
            @click="changeRoute('all-receipts')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Delivery Receipts <span class="rounded-number">{{ allReceiptCount }}</span></span>
            </template>
            <all-delivery-receipts
              class="mt-2 pt-75"
              @emitCountDeliveryReceipts="countDeliveryReceipts"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllDeliveryReceipts from './all-delivery-receipts/AllDeliveryReceipts.vue'
import LinkedDeliveryReceipts from './linked-delivery-receipts/LinkedDeliveryReceipts.vue'
import PendingDeliveryReceipts from './pending-delivery-receipts/PendingDeliveryReceipts.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllDeliveryReceipts,
    PendingDeliveryReceipts,
    LinkedDeliveryReceipts,
  },
  data() {
    return {
      allReceiptCount: 0,
      linkedReceiptCount: 0,
      pendingReceiptCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Pending',
          param: 'pending',
          count: 0,
        },
        {
          title: 'Linked',
          param: 'linked',
          count: 0,
        },
        {
          title: 'All Delivery Receipts',
          param: 'all-receipts',
          count: 0,
        },
      ],
      businessEntityOptions: [],
      vendorOptions: [],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countDeliveryReceipts()
    // this.$http.get('purchase/purchase-request/create/extra-options')
    //   .then(response => {
    //     this.businessEntityOptions = response.data.businessEntityOptions ?? []
    //     this.vendorOptions = response.data.vendorOptions ?? []
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countDeliveryReceipts() {
      this.$http.get('purchase/delivery-receipts/count')
        .then(response => {
          this.allReceiptCount = response.data.allReceiptCount
          this.linkedReceiptCount = response.data.linkedReceiptCount
          this.pendingReceiptCount = response.data.pendingReceiptCount

          this.pageTabs.forEach(element => {
            if (element.title === 'Pending') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingReceiptCount
            } else if (element.title === 'Linked') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.linkedReceiptCount
            } else if (element.title === 'All Delivery Receipts') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allReceiptCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
